import React, { Component } from 'react';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { Grid, TextField, Typography } from '@mui/material';
import { disableEnterKeyEventHandler, FlagActionDialogStyles, withFlagActionDialogStyes } from './FlagActionCommons';

interface Props extends ActionMenuItemProps, FlagActionDialogStyles {
	handleStatusChangeAction: (comment: string) => void;
}

interface State {
	comment: string;
	commentValid: boolean;
}

const initialState = {
	comment: '',
	commentValid: false
};

class ProceedFlagActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = initialState;
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
	}

	handleOpenDialog(): void {
		this.setState(initialState);
	}

	handleSubmit(): void {
		this.props.handleStatusChangeAction(this.state.comment);
	}

	handleChange(event: React.ChangeEvent<{ value: string }>): void {
		const newComment = event.target.value;
		this.setState({ comment: newComment, commentValid: newComment.trim() !== '' });
	}

	isSubmitDisabled(): boolean {
		return false;
	}

	render(): JSX.Element {
		const { renderMenuItem, classes } = this.props;
		const { comment } = this.state;

		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <AccessibilityNewIcon color="primary" />,
					text: 'Fall übernehmen'
				}}
				dialogProps={{
					danger: false,
					titleText: 'Fall übernehmen',
					saveButtonText: 'Fertig',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography>
							Du willst diesen Fall übernehmen? Klasse! Wenn du magst, kannst du noch einen Kommentar hinzufügen.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="standard"
							id="comment"
							name="comment"
							placeholder="Kommentar"
							autoComplete="off"
							multiline
							value={comment}
							className={classes.checkField}
							onChange={this.handleChange}
							onKeyDown={disableEnterKeyEventHandler}
							fullWidth
							autoFocus
						/>
					</Grid>
				</Grid>
			</DialogActionView>
		);
	}
}

export default withFlagActionDialogStyes(ProceedFlagActionView);
