import { connect } from 'react-redux';
import React, { Component } from 'react';
import CommentFlagActionView from './CommentFlagAction.view';
import { changeFlagStatus } from '../../store/FlaggedContent.actions';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { FlaggedContentState, FlaggedType } from '../../store/FlaggedContent.state';
import {
	ClientUserGeneratedContentFlagChatStatusEnum,
	ClientUserGeneratedContentFlagCommentStatusEnum,
	ClientUserGeneratedContentFlagGroupStatusEnum,
	ClientUserGeneratedContentFlagPostStatusEnum,
	ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';

interface Props extends ActionMenuItemProps {
	flag: FlaggedContentState['detail'];
	changeFlagStatus: (
		flagId: string,
		newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
		comment: string,
		snackbarMessage?: string,
		returnToTable?: boolean
	) => void;
}
function getFlagNewStatusAfterCommentOnly(
	flag: FlaggedContentState['detail']
): ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum | undefined {
	switch (flag.type) {
		case FlaggedType.CHAT:
			switch (flag.chat?.status) {
				case ClientUserGeneratedContentFlagChatStatusEnum.ACCEPTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.ACCEPTED;
				}
				case ClientUserGeneratedContentFlagChatStatusEnum.INPROGRESS: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.INPROGRESS;
				}
				case ClientUserGeneratedContentFlagChatStatusEnum.OPEN: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.OPEN;
				}
				case ClientUserGeneratedContentFlagChatStatusEnum.REJECTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.REJECTED;
				}
			}
			break;
		case FlaggedType.COMMENT: {
			switch (flag.comment?.status) {
				case ClientUserGeneratedContentFlagCommentStatusEnum.ACCEPTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.ACCEPTED;
				}
				case ClientUserGeneratedContentFlagCommentStatusEnum.INPROGRESS: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.INPROGRESS;
				}
				case ClientUserGeneratedContentFlagCommentStatusEnum.OPEN: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.OPEN;
				}
				case ClientUserGeneratedContentFlagCommentStatusEnum.REJECTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.REJECTED;
				}
			}
			break;
		}
		case FlaggedType.GROUP: {
			switch (flag.group?.status) {
				case ClientUserGeneratedContentFlagGroupStatusEnum.ACCEPTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.ACCEPTED;
				}
				case ClientUserGeneratedContentFlagGroupStatusEnum.INPROGRESS: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.INPROGRESS;
				}
				case ClientUserGeneratedContentFlagGroupStatusEnum.OPEN: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.OPEN;
				}
				case ClientUserGeneratedContentFlagGroupStatusEnum.REJECTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.REJECTED;
				}
			}
			break;
		}
		case FlaggedType.POST: {
			switch (flag.post?.status) {
				case ClientUserGeneratedContentFlagPostStatusEnum.ACCEPTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.ACCEPTED;
				}
				case ClientUserGeneratedContentFlagPostStatusEnum.INPROGRESS: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.INPROGRESS;
				}
				case ClientUserGeneratedContentFlagPostStatusEnum.OPEN: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.OPEN;
				}
				case ClientUserGeneratedContentFlagPostStatusEnum.REJECTED: {
					return ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.REJECTED;
				}
			}
		}
	}
}

class CommentFlagActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleStatusChangeAction = this.handleStatusChangeAction.bind(this);
	}

	handleStatusChangeAction(comment: string): void {
		const { flag, changeFlagStatus } = this.props;
		const newStatus = getFlagNewStatusAfterCommentOnly(flag);
		const flagId = flag.flagId;
		if (newStatus) {
			changeFlagStatus(flagId, newStatus, comment, 'Dein Kommentar wurde hinzugefügt.');
		}
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		return (
			<CommentFlagActionView renderMenuItem={renderMenuItem} handleStatusChangeAction={this.handleStatusChangeAction} />
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		flag: state.flaggedContent.detail
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		changeFlagStatus: (
			flagId: string,
			newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
			comment: string,
			snackbarMessage?: string,
			returnToTable?: boolean
		): Promise<void> => dispatch(changeFlagStatus(flagId, newStatus, comment, snackbarMessage, returnToTable))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentFlagActionContainer);
